import Header from '@/components/Header.vue';
import { ElMessageBox } from 'element-plus';
export default {
  name: 'Puzzle',
  components: {
    Header
  },

  data() {
    return {
      puzzleList: [// {
      //   complete: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fir-com.jpg',
      //   images: [
      //     { id: 0, url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fir-1.gif' },
      //     { id: 1, url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fir-2.gif' },
      //     { id: 2, url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fir-3.gif' },
      //     { id: 3, url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fir-4.gif' },
      //     { id: 4, url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fir-5.gif' },
      //     { id: 5, url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fir-6.gif' },
      //     { id: 6, url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fir-7.gif' },
      //     { id: 7, url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fir-8.gif' }
      //   ]
      // },
      {
        complete: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fourth-com.png',
        images: [{
          id: 0,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fourth-1.jpg'
        }, {
          id: 1,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fourth-2.jpg'
        }, {
          id: 2,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fourth-3.jpg'
        }, {
          id: 3,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fourth-4.jpg'
        }, {
          id: 4,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fourth-5.jpg'
        }, {
          id: 5,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fourth-6.jpg'
        }, {
          id: 6,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fourth-7.jpg'
        }, {
          id: 7,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fourth-8.jpg'
        }]
      }, {
        complete: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/sec-com.jpg',
        images: [{
          id: 0,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/sec-1.jpg'
        }, {
          id: 1,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/sec-2.jpg'
        }, {
          id: 2,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/sec-3.jpg'
        }, {
          id: 3,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/sec-4.jpg'
        }, {
          id: 4,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/sec-5.jpg'
        }, {
          id: 5,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/sec-6.jpg'
        }, {
          id: 6,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/sec-7.jpg'
        }, {
          id: 7,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/sec-8.jpg'
        }]
      }, {
        complete: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/third-com.jpg',
        images: [{
          id: 0,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/third-1.jpg'
        }, {
          id: 1,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/third-2.jpg'
        }, {
          id: 2,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/third-3.jpg'
        }, {
          id: 3,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/third-4.jpg'
        }, {
          id: 4,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/third-5.jpg'
        }, {
          id: 5,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/third-6.jpg'
        }, {
          id: 6,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/third-7.jpg'
        }, {
          id: 7,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/third-8.jpg'
        }]
      }, {
        complete: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fif-com.png',
        images: [{
          id: 0,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fif-1.jpg'
        }, {
          id: 1,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fif-2.jpg'
        }, {
          id: 2,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fif-3.jpg'
        }, {
          id: 3,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fif-4.jpg'
        }, {
          id: 4,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fif-5.jpg'
        }, {
          id: 5,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fif-6.jpg'
        }, {
          id: 6,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fif-7.jpg'
        }, {
          id: 7,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/fif-8.jpg'
        }]
      }, {
        complete: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/six-com.png',
        images: [{
          id: 0,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/six-1.jpg'
        }, {
          id: 1,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/six-2.jpg'
        }, {
          id: 2,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/six-3.jpg'
        }, {
          id: 3,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/six-4.jpg'
        }, {
          id: 4,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/six-5.jpg'
        }, {
          id: 5,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/six-6.jpg'
        }, {
          id: 6,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/six-7.jpg'
        }, {
          id: 7,
          url: 'https://fgbmy22.wearetogether.com.cn/public/puzzle/six-8.jpg'
        }]
      }],
      num: 0,
      list: [],
      time: 0,
      empty: 8,
      // 空白位置下标, 默认在最后
      selectedSub: [],
      // 选中的下标
      start: false,
      //是否开始游戏
      win: false,
      random: 0,
      // 设计一个随机数与id结合，实现每次开始游戏都以随机数加id形成新的key，让页面重载
      timeFunc: null,
      // 定时任务，方便清除,
      steps: 0 // 记录移动步数

    };
  },

  mounted() {
    let gameIndex = localStorage.getItem('gameIndex');

    if (gameIndex && gameIndex >= 0) {
      gameIndex *= 1;

      if (gameIndex >= this.puzzleList.length - 1) {
        this.num = 0;
        localStorage.setItem('gameIndex', this.num);
      } else {
        this.num = gameIndex + 1;
        localStorage.setItem('gameIndex', this.num);
      }
    } else {
      localStorage.setItem('gameIndex', this.num);
    }

    this.list = this.choosePic();
  },

  unmounted() {
    if (this.timeFunc) clearInterval(this.timeFunc);
  },

  methods: {
    handlePlay() {
      this.start = true; // 开始计时

      this.timeFunc = setInterval(this.startGame, 1000);
    },

    choosePic() {
      this.random = Math.ceil(Math.random() * 10000);
      const imgs = [...this.puzzleList[this.num].images];
      const choosePics = [];
      this.selectedSub = [];

      for (let i = 0; i < 8; i++) {
        const num = parseInt(Math.random() * imgs.length);
        this.selectedSub.push(imgs[num].id);
        choosePics.push(imgs[num]);
        imgs.splice(num, 1);
      }

      this.selectedSub.push(8); // 最后放入空白处下标

      if (this.solvability(this.selectedSub, 3)) {
        return choosePics;
      } else {
        this.reset();
        return this.choosePic();
      }
    },

    ifSuccess() {
      // 判断是否成功
      let step = 0;

      for (let i = 0; i < 9; i++) {
        if (this.selectedSub[i] === i) step += 1;
      }

      if (step === 9) {
        this.win = true;
        clearInterval(this.timeFunc);
        setTimeout(() => {
          ElMessageBox.alert('You Win!!!', 'Title', {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: 'OK',
            callback: () => {
              console.log(this.num, localStorage);

              if (this.num >= this.puzzleList.length - 1) {
                this.num = 0;
                localStorage.setItem('gameIndex', this.num);
              } else {
                this.num += 1;
                localStorage.setItem('gameIndex', this.num);
              }

              this.start = false;
              this.reset(); // 后续处理逻辑
            }
          });
        }, 1000);
      }
    },

    startGame() {
      this.time += 1;
    },

    move(param) {
      // 判断是否在空白位置的上下左右四个位置的点击
      // 上 empty - 3
      // 下 empty + 3
      // 左 empty - 1
      // 右 empty + 1
      // 根据点击的元素找到在选择列表里的下标，判断是否在空白处的上下左右位置
      const index = this.selectedSub.findIndex(item => item === param.id); // 判断index和空白下标的大小

      if (index < this.empty) {
        // 判断是在空白下标的上方还是左边
        if (this.empty - index === 1) {
          // 逻辑不够完善，没有考虑边界，要考虑左边一侧没有左侧的情况
          if (this.empty === 0 || this.empty === 3 || this.empty === 6) return;
          this.steps += 1; // 在空白下标左边
          // 将空白下标和index转换位置

          let arr = [...this.selectedSub];
          arr[index] = arr.splice(this.empty, 1, arr[index])[0];
          this.selectedSub = [...arr];
          this.empty = index; // 执行移动动画

          const distance = this.$refs[`part-${param.id}`][0].offsetWidth;
          const left = this.$refs[`part-${param.id}`][0].style.left;

          if (left) {
            this.$refs[`part-${param.id}`][0].style.left = +left.split('px')[0] + distance + 'px';
          } else {
            this.$refs[`part-${param.id}`][0].style.left = distance + 'px';
          }
        } else if (this.empty - index === 3) {
          // 考虑边界，0,1，2没有上方位置
          if (this.empty === 0 || this.empty === 1 || this.empty === 2) return;
          this.steps += 1;
          let arr = [...this.selectedSub];
          arr[index] = arr.splice(this.empty, 1, arr[index])[0];
          this.selectedSub = [...arr];
          this.empty = index; // 执行移动动画

          const distance = this.$refs[`part-${param.id}`][0].offsetHeight;
          const top = this.$refs[`part-${param.id}`][0].style.top;

          if (top) {
            this.$refs[`part-${param.id}`][0].style.top = +top.split('px')[0] + distance + 'px';
          } else {
            this.$refs[`part-${param.id}`][0].style.top = distance + 'px';
          } // 在空白下标上方

        } // 其余点击不予相应

      } else {
        // 判断是在空白下标的下方还是右边
        if (index - this.empty === 1) {
          if (this.empty === 2 || this.empty === 5 || this.empty === 8) return;
          this.steps += 1; // 将空白下标和index转换位置

          let arr = [...this.selectedSub];
          arr[index] = arr.splice(this.empty, 1, arr[index])[0];
          this.selectedSub = [...arr];
          this.empty = index; // 执行移动动画

          const distance = this.$refs[`part-${param.id}`][0].offsetWidth;
          const left = this.$refs[`part-${param.id}`][0].style.left;

          if (left) {
            this.$refs[`part-${param.id}`][0].style.left = +left.split('px')[0] - distance + 'px';
          } else {
            this.$refs[`part-${param.id}`][0].style.left = -distance + 'px';
          }
        } else if (index - this.empty === 3) {
          if (this.empty === 6 || this.empty === 7 || this.empty === 8) return;
          this.steps += 1;
          let arr = [...this.selectedSub];
          arr[index] = arr.splice(this.empty, 1, arr[index])[0];
          this.selectedSub = [...arr];
          this.empty = index; // 执行移动动画

          const distance = this.$refs[`part-${param.id}`][0].offsetHeight;
          const top = this.$refs[`part-${param.id}`][0].style.top;

          if (top) {
            this.$refs[`part-${param.id}`][0].style.top = +top.split('px')[0] - distance + 'px';
          } else {
            this.$refs[`part-${param.id}`][0].style.top = -distance + 'px';
          }
        }
      }

      this.ifSuccess();
    },

    solvability(order, size) {
      // 判断随机数据是否有解
      let a;
      let count = 0;
      let m = 0;
      let n = 0;
      const len = order.length;
      size = size || 3;

      for (let i = 0; i < len; i++) {
        a = order[i];

        if (a == size * size - 1) {
          m = parseInt(i / size);
          n = parseInt(i % size);
        }

        for (let j = i + 1; j < len; j++) {
          if (order[j] < a) {
            count++;
          }
        }
      }

      count += m;
      count += n;
      return count % 2 == 0;
    },

    reset() {
      this.selectedSub = [];
      this.empty = 8;
      this.time = 0;
      this.steps = 0;
      this.list = [...this.choosePic()];
    }

  }
};